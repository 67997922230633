import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "types";
import useAlert from "hooks/useAlert";
import useDebounce from "hooks/useDebounce";

const AlertListener: React.FC = () => {
  const alerts = useSelector((state: RootState) => state.alerts.alerts);
  const { showAlert } = useAlert();

  const debouncedAlerts = useDebounce(alerts, 600);
  useEffect(() => {
    debouncedAlerts.forEach((alert) => {
      showAlert(alert.variant, alert.message);
    });
  }, [debouncedAlerts]);

  return null;
};

export default AlertListener;
