import { Provider } from "react-redux";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { appStore, persistor } from "./modules/store";
import ReactGA from "react-ga4";
import "./styles/components/Dashboard.css";
import "./styles/print.css";
import "./fonts.css";
import "./index.css";

import { theme } from "./styles/mui-theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { OnlineStatusProvider } from "./hooks/useOnlineStatus";
import AppRoute from "./app-route";
import Layout from "./components/layout";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import CacheBuster from "react-cache-buster";
import object from "../package.json";
import { createRoot } from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import AlertProvider from "components/lynx-components/alert/AlertProvider";

ReactGA.initialize("G-BZ2L5XJWK0");

export const store = appStore;
LicenseInfo.setLicenseKey(
  "21167caa351901ed8731da6769476427Tz05NTg5MyxFPTE3NTQ4MzY4MzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=="
);
TimeAgo.addDefaultLocale(en);
const container = document.getElementById("root");
const root = createRoot(container);

const AppRedux = (props) => {
  const isProduction = process.env.NODE_ENV === "production";
  return (
    <CacheBuster
      currentVersion={object.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      metaFileDirectory={"."}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AlertProvider>
              <Router history={useHistory()}>
                <OnlineStatusProvider>
                  <Layout>
                    <AppRoute />
                  </Layout>
                </OnlineStatusProvider>
              </Router>
            </AlertProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </CacheBuster>
  );
};

root.render(<AppRedux />);
