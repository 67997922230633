import styled from "@emotion/styled";
import AlertListener from "components/AlertListener";
import lynxColors from "modules/lynxColors";
import { SnackbarProvider } from "notistack";
import React, { FC } from "react";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const Snackbar = styled(SnackbarProvider)((theme) => ({
  "&.SnackbarItem-variantSuccess": {
    background: lynxColors.success,
  },
  "&.SnackbarItem-variantError": {
    background: lynxColors.error,
  },
}));

const AlertProvider: FC<Props> = ({ children }) => {
  return (
    <Snackbar classes={{ variantSuccess: "variantSuccess" }}>
      {children}
      <AlertListener />
    </Snackbar>
  );
};

export default AlertProvider;
