import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "components/lynx-components";
import { getAssets } from "../../../services/assets";
import {
  createMappings,
  createTrasnlations,
  getImportSpecifications,
  proceedWithImport,
  saveSpec,
  startImport,
  uploadAndPrepImport,
  uploadCorrectedImport,
} from "../../../services/import";
import { getLookups } from "../../../services/lookup";
import { getCustomFields } from "../../../services/custom-fields-service";
import { LynxDialog } from "../../lynx-dialog";
import {
  getEventColumns,
  latLongColumns,
  utmColumns,
} from "../events/event-columns";
import "./imports.css";
import { Step1 } from "./step1";
import { Step2 } from "./step2";
import { Step3 } from "./step3";
import { Step4 } from "./step4";
import { Step5 } from "./step5";
import { Step6 } from "./step6";
import { Step7 } from "./step7";

import { validationService } from "../../../services";
import { EntityTypes, LookupTypes } from "../../../types/enums";
import { monitoringResultColumns } from "../monitoring/monitroing-result-columns";
import { transformCustomFieldDefsToColumnDefs } from "./../../../services/custom-fields-service";
import { validateFileSize } from "../../../services/attachments";
import { useSelector } from "react-redux";
import useAlert from "hooks/useAlert";
export function Imports() {
  const initialFormState = {
    assetId: "",
    assetName: "",
    parentLookupId: "",
    monitoringLocationId: "",
    parentLookupCode: "",
    childLookupId: "",
    childLookupCode: "",
    monitoringLocationName: "",
    name: "",
    description: "",
    nameError: "",
    descriptionError: "",
    dateTimeFormat: "",
    dateFormat: "",
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedSpecId, setSelectedSpecId] = React.useState("");
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [entityType, setEntityType] = useState("");
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isSaveNew, setIsSaveNew] = useState(true);
  const [preImporting, setPreImporting] = useState(false);
  const [importColumnHeaders, setImportColumnHeaders] = useState([]);
  const [lynxFields, setLynxFields] = useState([]);
  const [formState, setFormState] = useState(initialFormState);
  const [preImportingMessage, setPreImportingMessage] = useState(null);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [specs, setSpecs] = useState([]);
  const [assets, setAssets] = useState([]);
  const [eventCategories, setEventCategories] = useState([]);
  const [eventSubCategories, setEventSubcategories] = useState([]);
  const [availableSubCategories, setAvailableSubCategories] = useState([]);
  const [showError, setShowError] = useState(false);
  const [fieldMappings, setFieldMappings] = useState([]);
  const [translations, setTranslations] = useState(null);
  const [importHistory, setImportHistory] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [translationsObj, setTranslationsObj] = useState([]);
  const [monitoringLocations, setMonitoringLocations] = useState([]);
  const [customFields, setCustomFields] = useState(null);
  const [monitoringEventTypes, setMonitoringEventTypes] = useState([]);
  const [isCustomFormat, setIsCustomFormat] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    title: "",
    description: "",
  });
  const [importSummary, setImportSummary] = useState({
    importableRows: 0,
    errorRows: 0,
    errorMessages: [],
  });
  const [selectedSpec, setSelectedSpec] = useState({});
  const [showFileImporting, setShowFileImporting] = useState(false);
  const history = useHistory();
  const { users } = useSelector((state) => state.lookups);
  const predefinedLookups = { users: users };
  const { showAlert } = useAlert();

  useEffect(() => {
    const spec = specs.find((x) => x.id == selectedSpecId);
    if (spec) {
      setSelectedSpec(spec);
      setFormState({
        ...formState,
        assetId: spec.assetId,
        parentLookupId: spec.parentLookupId,
        parentLookupCode: spec.parentLookupId
          ? entityType == EntityTypes.Event
            ? eventCategories.find((x) => x.id == spec.parentLookupId).code
            : monitoringEventTypes.find((x) => x.id == spec.parentLookupId).code
          : "",
        childLookupCode: spec.childLookupId
          ? eventSubCategories.find((x) => x.id == spec.childLookupId).code
          : "",
        childLookupId: spec.childLookupId,
        name: spec.name,
        assetName: spec.assetId
          ? assets.find((x) => x.id == spec.assetId).name
          : "",
        description: spec.description,
      });
    }
  }, [selectedSpecId]);

  useEffect(() => {
    if (translations != null && !_.isEmpty(lynxFields)) {
      let newTranslations = _.cloneDeep(translations);
      for (var i = 0; i < newTranslations.length; i++) {
        const currentTranslation = newTranslations[i];
        const eventField = lynxFields.find(
          (x) => x.field == currentTranslation.fieldName
        );
        const existingTranslation = translations.find(
          (x) => x.fieldName == currentTranslation.fieldName
        );
        if (eventField) {
          newTranslations[i].fieldLabel = eventField.headerName;
          newTranslations[i].translationValues = newTranslations[
            i
          ].fromValues.map((value) => ({
            fromValue: value,
            toValue:
              existingTranslation &&
              existingTranslation.translationValues &&
              existingTranslation.translationValues.find(
                (x) => x.fromValue == value
              )
                ? existingTranslation.translationValues.find(
                    (x) => x.fromValue == value
                  ).toValue
                : currentTranslation &&
                  currentTranslation.toValues &&
                  currentTranslation.toValues.find(
                    (x) => x.toLowerCase() == value.toLowerCase()
                  )
                ? currentTranslation.toValues.find((x) => x == value)
                : "",
          }));
        }
      }
      setTranslationsObj(newTranslations);

      setPreImporting(false);
    }
  }, [translations, lynxFields]);

  useEffect(() => {
    if (!_.isEmpty(importColumnHeaders) && !_.isEmpty(lynxFields)) {
      if (_.isEmpty(fieldMappings)) {
        let mapArray = [];
        importColumnHeaders.forEach((col) => {
          var exisitingField = lynxFields.find((x) => x.headerName == col);
          let mapObject = {
            lynxField: exisitingField ? exisitingField.field : "",
            importColumn: col,
          };
          mapArray.push(mapObject);
        });
        setFieldMappings(mapArray);
      }
    }

    if (
      !_.isEmpty(importColumnHeaders) &&
      entityType == EntityTypes.TimeSeries
    ) {
      if (_.isEmpty(fieldMappings)) {
        let mapArray = [];
        importColumnHeaders.forEach((col) => {
          let mapObject = {
            lynxField: "",
            importColumn: col,
          };
          mapArray.push(mapObject);
        });
        setFieldMappings(mapArray);
      }
    }
  }, [importColumnHeaders, lynxFields, entityType]);

  useEffect(() => {
    if (!_.isEmpty(entityType)) {
      getImportSpecifications({ entityType: entityType }).then((res) => {
        setSpecs(res.data);
      });
      getAssets().then((res) => {
        setAssets(res.data);
      });

      if (_.toLower(entityType) == _.toLower(EntityTypes.Event)) {
        getEventLookups();
        getCustomFields(EntityTypes.Event).then((res) => {
          setCustomFields(res.data);
        });
      }

      if (_.toLower(entityType) == _.toLower(EntityTypes.MonitoringResult)) {
        getMonitoringEventLookups();
        getCustomFields(EntityTypes.MonitoringEvent).then((res) => {
          setCustomFields(res.data);
        });
      }
    }
  }, [entityType]);

  useEffect(() => {
    if (formState.parentLookupId && !_.isEmpty(eventSubCategories)) {
      setAvailableSubCategories(
        eventSubCategories.filter(
          (x) => x.parentLookupId == formState.parentLookupId
        )
      );
    }
  }, [eventSubCategories, formState.parentLookupId]);

  useEffect(() => {
    if (customFields != null && formState && entityType) {
      let newColumns = [];
      if (entityType == EntityTypes.Event) {
        var eventColumns = getEventColumns(false, true, predefinedLookups);
        newColumns = [
          ...eventColumns.filter((x) => x.importable),
          ...latLongColumns,
          ...utmColumns,
          ...transformCustomFieldDefsToColumnDefs(customFields),
        ];
      }
      if (entityType == EntityTypes.MonitoringResult) {
        newColumns = [
          ...monitoringResultColumns.filter((x) => x.importable),
          ...latLongColumns,
          ...utmColumns,
          ...transformCustomFieldDefsToColumnDefs(customFields),
        ];
      }

      if (formState.assetName != "") {
        newColumns = newColumns.filter((x) => x.field != "assetName");
      }

      if (formState.monitoringLocationName != "") {
        newColumns = newColumns.filter(
          (x) => x.field != "monitoringLocationName"
        );
      }

      if (entityType == EntityTypes.MonitoringResult) {
        if (formState.parentLookupCode != "") {
          newColumns = newColumns.filter(
            (x) => x.field != "monitoringEventType"
          );
          newColumns = newColumns.filter(
            (x) =>
              !x.isCustomField ||
              (x.isCustomField && !x.eventCategoryId) ||
              (x.isCustomField && x.eventCategoryId == formState.parentLookupId)
          );
        }
        if (formState.childLookupCode != "") {
          newColumns = newColumns.filter(
            (x) =>
              !x.isCustomField ||
              (x.isCustomField && !x.eventCategoryId) ||
              (x.isCustomField &&
                x.eventCategoryId == formState.parentLookupId &&
                x.eventSubCategoryId == formState.childLookupId)
          );
        }
      }
      if (entityType == EntityTypes.Event) {
        if (formState.parentLookupCode != "" && formState.childLookupId == "") {
          newColumns = newColumns.filter(
            (x) =>
              !x.isCustomField ||
              (x.isCustomField && !x.eventCategoryId) ||
              (x.isCustomField &&
                x.eventCategoryId == formState.parentLookupId &&
                !x.eventSubCategoryId)
          );
        }
        if (formState.parentLookupCode != "" && formState.childLookupId != "") {
          newColumns = newColumns.filter(
            (x) =>
              !x.isCustomField ||
              (x.isCustomField && !x.eventCategoryId) ||
              (x.isCustomField &&
                x.eventCategoryId == formState.parentLookupId &&
                x.eventSubCategoryId == formState.childLookupId) ||
              (x.isCustomField &&
                x.eventCategoryId == formState.parentLookupId &&
                !x.eventSubCategoryId)
          );
        }
      }
      setLynxFields(newColumns);
    }
  }, [
    customFields,
    formState.childLookupCode,
    formState.parentLookupCode,
    formState.assetName,
    formState.monitoringLocationName,
    entityType,
  ]);

  const getEventLookups = () => {
    getLookups(LookupTypes.EventCategory).then((res) => {
      setEventCategories(res.data);
    });
    getLookups(LookupTypes.EventSubCategory).then((res) => {
      setEventSubcategories(res.data);
    });
  };

  const getMonitoringEventLookups = () => {
    getLookups(LookupTypes.MonitoringLocations).then((res) => {
      setMonitoringLocations(res.data);
    });
    getLookups(LookupTypes.MonitoringEventType).then((res) => {
      setMonitoringEventTypes(res.data);
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleUploadAndNext = () => {
    setPreImporting(true);
    uploadAndPrepImport(uploadedFile, entityType, selectedSpec.id)
      .then((res) => {
        setPreImporting(false);
        setImportHistory(res.data.importHistory);
        setImportColumnHeaders(res.data.columnHeaders);
        handleNext();
      })
      .catch((err) => {
        setShowError(true);
        setErrorMessage({ title: err.response.data.message });
        setUploadedFile(null);
        setPreImporting(false);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFileUpload = (e, isReupload) => {
    const selectedFiles = e.target.files;
    if (selectedFiles[0] != null) {
      if (validateFileSize([selectedFiles[0]])) {
        setUploadedFile(selectedFiles[0]);
      } else {
        setShowFileSizeError(true);
      }
    }

    if (isReupload && selectedFiles[0] != null) {
      if (validateFileSize([selectedFiles[0]])) {
        handleUploadCorrectedFile(selectedFiles[0]);
      } else {
        setShowFileSizeError(true);
      }
    }
  };

  const handleUploadCorrectedFile = (file) => {
    setImportSummary({
      importableRows: 0,
      errorRows: 0,
      errorMessages: [],
      fatalErrorMessages: [],
      rowErrorMessages: [],
    });
    setPreImporting(true);
    uploadCorrectedImport(file, entityType, selectedSpec.id, importHistory.id)
      .then((res) => {
        setPreImporting(false);
        setImportSummary(res.data);
        setTranslations(res.data.importTranslationDtos);
      })
      .catch((err) => {
        setShowError(true);
        setErrorMessage({ title: err.response.data.message });
        setUploadedFile(null);
        setPreImporting(false);
      });
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    if (name == "parentLookupId") {
      if (value) {
        newState.parentLookupCode =
          entityType == EntityTypes.Event
            ? eventCategories.find((x) => x.id == value).code
            : monitoringEventTypes.find((x) => x.id == value).code;
      } else {
        newState.parentLookupCode = "";
      }
    }
    if (name == "assetId") {
      if (value) {
        newState.assetName = assets.find((x) => x.id == value).name;
      } else {
        newState.assetName = "";
      }
    }
    if (name == "childLookupId") {
      if (value) {
        newState.childLookupCode = eventSubCategories.find(
          (x) => x.id == value
        ).code;
      } else {
        newState.childLookupCode = "";
      }
    }
    if (
      name == "monitoringLocationId" &&
      entityType != EntityTypes.TimeSeries
    ) {
      if (value) {
        newState.monitoringLocationName = monitoringLocations.find(
          (x) => x.id == value
        ).code;
      } else {
        newState.monitoringLocationName = "";
      }
    }
    setFormState(newState);
  };

  const steps = [
    {
      label: "What type of data do you wish to import?",
    },
    {
      label: "Select or create an import configuration",
    },
    {
      label: "Select a file to import",
    },
    {
      label: "Mappings",
    },
    {
      label: "Translations",
    },
    {
      label: "Import",
    },
    {
      label: "Summary",
    },
  ];

  const handleStep4Next = () => {
    validateFieldMappingsAndCreate();
  };

  const handleDeleteMapping = (mapping) => {
    let newMappings = _.cloneDeep(fieldMappings);
    var indexToDelete = newMappings.findIndex(
      (x) => x.importColumn == mapping.importColumn
    );
    newMappings.splice(indexToDelete, 1);
    setFieldMappings(newMappings);
  };

  const validateFieldMappingsAndCreate = () => {
    let newMappings = [...fieldMappings];
    setPreImporting(true);
    if (_.isEmpty(selectedSpec)) {
      if (entityType == EntityTypes.Event) {
        if (!validateEventMappings(newMappings)) return;
      }
      if (entityType == EntityTypes.MonitoringResult) {
        if (!validateMonitoringMappings(newMappings)) return;
      }
      if (entityType == EntityTypes.TimeSeries) {
        if (!validateTimeseriesMappings(newMappings)) return;
      }
    }

    let dto = {
      ...formState,
      importSpecificationId: !_.isEmpty(selectedSpec) ? selectedSpec.id : null,
      importHistoryId: importHistory.id,
      entityType: entityType,
      importFieldMappings:
        entityType == EntityTypes.TimeSeries
          ? newMappings
              .filter(
                (x) =>
                  x.isTimeSeriesDateTime ||
                  x.isTimeSeriesMetadata ||
                  (x.parameterId && x.unitId)
              )
              .map((x) => ({ importColumnName: x.importColumn, ...x }))
          : [],
      mappings:
        _.isEmpty(selectedSpec) && entityType != EntityTypes.TimeSeries
          ? Object.assign(
              {},
              ...newMappings
                .filter((a) => a.lynxField != "")
                .map((a) => ({
                  [a.lynxField]: a.importColumn,
                }))
            )
          : {},
    };
    if (!isCustomFormat) {
      dto.dateTimeFormat = "";
      dto.dateFormat = "";
    }
    createMappings(dto)
      .then((res) => {
        setTranslations(res.data);

        handleNext();
        if (_.isEmpty(res.data) || entityType == EntityTypes.TimeSeries) {
          handleNext();
          setPreImporting(false);
        }
      })
      .catch((err) => {
        setShowError(true);
        setErrorMessage({ title: err.response.data.message });
        setPreImporting(false);
      });
  };

  const validateMonitoringMappings = (fieldMappings) => {
    let hasMonLoc = formState.monitoringLocationId != "";
    let hasType = formState.parentLookupId != "";
    let hasSampleId = false;

    let hasDate = false;
    let hasParam = false;
    let hasUnit = false;
    let hasValue = false;
    fieldMappings.forEach((mapping) => {
      if (mapping.lynxField == "monitoringEventEndDateTimeUtc") {
        hasDate = true;
      }
      if (mapping.lynxField == "monitoringLocationName") {
        hasMonLoc = true;
      }
      if (mapping.lynxField == "monitoringEventType") {
        hasType = true;
      }
      if (mapping.lynxField == "monitoringEventName") {
        hasSampleId = true;
      }
      if (mapping.lynxField == "parameterName") {
        hasParam = true;
      }
      if (mapping.lynxField == "unitName") {
        hasUnit = true;
      }
      if (mapping.lynxField == "enteredValue") {
        hasValue = true;
      }
    });
    if (
      !hasMonLoc ||
      !hasType ||
      !hasDate ||
      !hasSampleId ||
      !hasUnit ||
      !hasValue ||
      !hasParam
    ) {
      setShowError(true);
      setPreImporting(false);
      setErrorMessage({
        title: "Invalid Mappings",
        description:
          "The result requires a monitoring location, sample ID, event type, end date, parameter, value, and unit. Please ensure all required fields are mapped on the configuration.",
      });
      return false;
    }
    return true;
  };
  const validateEventMappings = (fieldMappings) => {
    let hasDate = false;

    fieldMappings.forEach((mapping) => {
      if (mapping.lynxField == "eventDateTime") {
        hasDate = true;
      }
    });

    if (!hasDate) {
      setShowError(true);
      setPreImporting(false);
      setErrorMessage({
        title: "Invalid Mappings",
        description:
          "The event requires a completed date. Please ensure all required fields are mapped on the configuration.",
      });
      return false;
    }
    return true;
  };
  const validateTimeseriesMappings = (fieldMappings) => {
    let hasDate = false;
    let hasValidResult = false;
    let hasError = false;

    fieldMappings.forEach((mapping) => {
      mapping.error = "";
      if (mapping.isTimeSeriesDateTime) {
        hasDate = true;
      }
      if (mapping.parameterId && mapping.unitId) {
        hasValidResult = true;
      }
      if (mapping.isResultValue && (!mapping.parameterId || !mapping.unitId)) {
        hasError = true;
        mapping.error = "Parameter and Unit are required.";
      }
      if (mapping.isTimeSeriesMetadata && !mapping.timeSeriesMetadataLabel) {
        hasError = true;
        mapping.error = "Label is required.";
      }
    });
    //find duplicate result mappings
    const map = new Map();
    fieldMappings.forEach((item, index) => {
      if (item.isResultValue) {
        const key = `${item.parameterId}-${item.unitId}`;
        if (!map.has(key)) {
          map.set(key, { count: 0, indices: [] });
        }
        const entry = map.get(key);
        entry.count++;
        entry.indices.push(index);
      }
    });
    const duplicateResultIndices = [];
    map.forEach((value) => {
      if (value.count > 1) {
        duplicateResultIndices.push(...value.indices);
      }
    });
    duplicateResultIndices.forEach((index) => {
      fieldMappings[index].error =
        "Duplicate result mapping. Ensure only one result + unit combination exists.";
    });
    //find duplicate metadata mappings
    const metaMap = new Map();
    fieldMappings.forEach((item, index) => {
      if (item.isTimeSeriesMetadata) {
        const key = `${item.timeSeriesMetadataLabel}`;
        if (!metaMap.has(key)) {
          metaMap.set(key, { count: 0, indices: [] });
        }
        const entry = metaMap.get(key);
        entry.count++;
        entry.indices.push(index);
      }
    });
    const duplicateMetaIndices = [];
    metaMap.forEach((value) => {
      if (value.count > 1) {
        duplicateMetaIndices.push(...value.indices);
      }
    });
    duplicateMetaIndices.forEach((index) => {
      fieldMappings[index].error =
        "Duplicate metadata mapping. Each metadata field must have a unique mapping.";
    });
    hasError =
      !hasDate ||
      !hasValidResult ||
      hasError ||
      !_.isEmpty(duplicateResultIndices) ||
      !_.isEmpty(duplicateMetaIndices);
    if (hasError) {
      setShowError(true);
      setFieldMappings(fieldMappings);
      setPreImporting(false);
      setErrorMessage({
        title: "Invalid Mappings",
        description: !_.isEmpty(duplicateResultIndices)
          ? "Duplicate result mappings exist. Ensure only one result + unit combination exists."
          : !_.isEmpty(duplicateMetaIndices)
          ? "Duplicate metadata mappings exist. Each metadata field must have a unique mapping."
          : "Timeseries requires a date time mapping and at least one result value. Please ensure all required fields are mapped on the configuration.",
      });
      return false;
    }
    return true;
  };
  const handleCreateTranslations = () => {
    let dto = {
      importHistoryId: importHistory.id,
      importSpecificationId: !_.isEmpty(selectedSpec) ? selectedSpec.id : null,
      importTranslations: translationsObj.map((obj) => ({
        fieldName: obj.fieldName,
        translationValues: obj.translationValues.filter((x) => x.toValue != ""),
      })),
    };
    createTrasnlations(dto)
      .then((res) => {
        handleNext();
      })
      .catch((err) => {
        setShowError(true);
        setErrorMessage({ title: err.response.data.message });
      });
  };

  const handleImport = () => {
    if (isSaveNew && _.isEmpty(selectedSpec)) {
      setPreImportingMessage("Saving import configuration...");
    }
    setPreImporting(true);
    let newState = { ...formState };
    if (isSaveNew) {
      let isFormValid = false;
      validationService.validateRequiredField(
        newState,
        "name",
        "nameError",
        "Name"
      );
      if (entityType == EntityTypes.TimeSeries) {
        validationService.validateRequiredField(
          newState,
          "monitoringLocationId",
          "monitoringLocationIdError",
          "Monitoring Location"
        );
      }
      isFormValid = !validationService.hasError(
        newState,
        "nameError",
        "monitoringLocationIdError"
      );
      if (!isFormValid) {
        setFormState(newState);
        showAlert("error", "Form not valid for saving.");
        setPreImporting(false);
        return;
      }
    }

    const dto = {
      importHistoryId: importHistory.id,
      saveNew: isSaveNew,
      name: formState.name,
      description: formState.description,
      importSpecificationId: !_.isEmpty(selectedSpec) ? selectedSpec.id : null,
      monitoringLocationId: formState.monitoringLocationId,
    };
    if (isSaveNew && _.isEmpty(selectedSpec)) {
      saveSpec(dto).then((res) => {
        setPreImportingMessage(null);
        startImport(dto)
          .then((res) => {
            setImportSummary(res.data);
            setPreImporting(false);
            handleNext();
          })
          .catch((err) => {
            setShowError(true);
            setErrorMessage({ title: err.response.data.message });
            setPreImporting(false);
          });
      });
    } else {
      startImport(dto)
        .then((res) => {
          setImportSummary(res.data);
          setPreImporting(false);
          handleNext();
        })
        .catch((err) => {
          setShowError(true);
          setErrorMessage({ title: err.response.data.message });
          setPreImporting(false);
        });
    }
  };
  const handleImportFile = () => {
    setShowFileImporting(true);
    proceedWithImport(importHistory.id)
      .then((res) => {
        setImportHistory(res.data);
        setShowFileImporting(false);
        setShowSuccess(true);
      })
      .catch((err) => {
        setShowFileImporting(false);
        setPreImporting(false);
        setShowError(true);
        setErrorMessage({ title: err.response.data.message });
      });
  };

  const handleSetEntityType = (eT) => {
    if (eT != entityType) {
      setFormState(initialFormState);
    }
    setEntityType(eT);
  };

  const handleSetCreateNew = (e) => {
    if (e != isCreateNew) {
      setFormState(initialFormState);
      setSelectedSpecId("");
      setSelectedSpec({});
    }
    setIsCreateNew(e);
  };

  const handleLabelChange = (e, index) => {
    let newMappings = [...fieldMappings];
    newMappings[index].timeSeriesMetadataLabel = e.target.value;
    setFieldMappings(newMappings);
  };

  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Imports
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">Imports</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Grid.Row className="ml-5 mr-0">
        <Paper elevation={3} className="p-2">
          <Box sx={{ width: 850 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === steps.length - 1 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    {index == 0 && (
                      <Step1
                        entityType={entityType}
                        handleSetEntityType={handleSetEntityType}
                        handleBack={handleBack}
                        handleNext={handleNext}
                      />
                    )}
                    {index == 1 && (
                      <Step2
                        isCreateNew={isCreateNew}
                        setIsCreateNew={handleSetCreateNew}
                        selectedSpecId={selectedSpecId}
                        isCustomFormat={isCustomFormat}
                        setIsCustomFormat={setIsCustomFormat}
                        setSelectedSpecId={setSelectedSpecId}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        entityType={entityType}
                        handleInputChange={handleInputChange}
                        formState={formState}
                        specs={specs}
                        assets={assets}
                        eventCategories={eventCategories}
                        eventSubCategories={eventSubCategories}
                        availableSubCategories={availableSubCategories}
                        monitoringLocations={monitoringLocations}
                        monitoringEventTypes={monitoringEventTypes}
                      />
                    )}
                    {index == 2 && (
                      <Step3
                        uploadedFile={uploadedFile}
                        setUploadedFile={setUploadedFile}
                        handleFileUpload={handleFileUpload}
                        handleBack={handleBack}
                        handleNext={handleUploadAndNext}
                      />
                    )}
                    {index == 3 && (
                      <Step4
                        entityType={entityType}
                        formState={formState}
                        handleBack={handleBack}
                        handleNext={handleStep4Next}
                        lynxFields={lynxFields}
                        fieldMappings={fieldMappings}
                        handleLabelChange={handleLabelChange}
                        setFieldMappings={(mappings) => {
                          setFieldMappings(mappings);
                        }}
                        handleDeleteMapping={handleDeleteMapping}
                        selectedSpec={selectedSpec}
                      />
                    )}
                    {index == 4 && (
                      <Step5
                        handleBack={handleBack}
                        handleNext={handleCreateTranslations}
                        translationsObj={translationsObj}
                        setTranslationsObj={setTranslationsObj}
                      />
                    )}
                    {index == 5 && (
                      <Step6
                        isSaveNew={isSaveNew}
                        entityType={entityType}
                        setIsSaveNew={setIsSaveNew}
                        handleBack={handleBack}
                        handleNext={handleImport}
                        formState={formState}
                        handleInputChange={handleInputChange}
                        selectedSpec={selectedSpec}
                      />
                    )}
                    {index == 6 && (
                      <Step7
                        importSummary={importSummary}
                        handleImportFile={handleImportFile}
                        handleFileUpload={(e) => {
                          handleFileUpload(e, true);
                        }}
                        handleBack={handleBack}
                      />
                    )}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Paper>
      </Grid.Row>
      {activeStep == 0 && (
        <Grid.Row className="ml-5 mr-0 mt-2 mb-2">
          <Paper elevation={3} className="p-2">
            <Box sx={{ width: 850 }}>
              <Button
                variant="outlined"
                className="mr-2"
                onClick={(e) => handleNavigateTo(e, "/import-history")}
              >
                View Import History
              </Button>
              <Button
                onClick={(e) => handleNavigateTo(e, "/import-configurations")}
                variant="outlined"
              >
                View Import Configurations
              </Button>
            </Box>
          </Paper>
        </Grid.Row>
      )}

      <LynxDialog
        open={preImporting}
        title={
          preImportingMessage ??
          "Processing the import file. Do not close the window."
        }
        description={
          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          </>
        }
      />
      <LynxDialog
        open={showError}
        title={errorMessage.title}
        description={errorMessage.description}
        handleConfirm={() => setShowError(false)}
      />
      <LynxDialog
        open={showSuccess}
        title={"File has been Imported"}
        description={`${importHistory.numberOfSuccessRows} rows have imported successfully.`}
        handleConfirm={(e) => {
          setShowSuccess(false);
          handleNavigateTo(e, "/import-history");
        }}
      />
      <LynxDialog
        open={showFileImporting}
        title={"DO NOT CLOSE THE WINDOW. File is importing in the background."}
        description={
          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          </>
        }
      />
      <LynxDialog
        open={showFileSizeError}
        handleClose={() => setShowFileSizeError(false)}
        title={`File size exceeded`}
        description={
          "Attachments are over the required total size limit of 50MB."
        }
        handleConfirm={() => setShowFileSizeError(false)}
      />
    </Grid>
  );
}
