import GroupIcon from "@mui/icons-material/Group";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip, { chipClasses } from "@mui/material/Chip";
import MuiGrid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Grid, Dimmer } from "components/lynx-components";
import {
  createEntityContact,
  deleteEntityContact,
  getEntityContacts,
  searchContacts,
} from "../../../services/contacts";
import { EventContactTypes, UserRoles } from "../../../types/enums";
import { LynxDialog } from "../../lynx-dialog";
import { ContactForm } from "../contacts/contact-form";
import { ContactGroupModal } from "../contacts/contact-group-modal";
import Popover from "@mui/material/Popover";
import { ContactQuickCard } from "../contacts/contact-quick-card";
import { roleMatch } from "actions/auth";
import useAlert from "hooks/useAlert";
import { ContactSearchResultDto, EventContactDto } from "types";

interface ContactsProps {
  entityId?: number;
  entityType?: string;
  isLoading?: boolean;
  handleSetContacts?: (contacts: EventContactDto[]) => void;
  eventLocked?: boolean;
}

const Contacts: FC<ContactsProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [existingContacts, setExistingContacts] = useState<EventContactDto[]>(
    []
  ); // Adjust type
  const [showAddContact, setShowAddContact] = useState(false);
  const [showAddContactGroup, setShowAddContactGroup] = useState(false);
  const [options, setOptions] = useState<ContactSearchResultDto[]>([]); // Adjust type
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedContact, setSelectedContact] = useState<EventContactDto>({}); // Adjust type
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [autoCompleteContactValue, setAutoCompleteContactValue] = useState<
    any | null
  >(null); // Adjust type
  const [autoCompleteContactInputValue, setAutoCompleteContactInputValue] =
    useState("");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { showAlert } = useAlert();

  useEffect(() => {
    if (props.entityId && props.entityType && !props.isLoading) {
      getEntityContacts(props.entityType, props.entityId).then((res) => {
        setExistingContacts(res.data);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [props.entityId, props.entityType, props.isLoading]);

  useEffect(() => {
    if (autoCompleteContactInputValue === "") {
      setOptions(autoCompleteContactValue ? [autoCompleteContactValue] : []);
      return;
    }
    setOptionsLoading(true);
    searchContacts(autoCompleteContactInputValue).then((res) => {
      setOptions(res.data);
      setOptionsLoading(false);
    });
  }, [autoCompleteContactValue, autoCompleteContactInputValue]);

  const handleContactDeleted = (contact: EventContactDto) => {
    // Adjust type
    const newContacts = _.cloneDeep(existingContacts);
    const index = newContacts.findIndex(
      (x) => x.id === contact.id && x.type === contact.type
    );

    if (index !== -1) {
      newContacts.splice(index, 1);

      if (props.entityId) {
        setIsLoading(true);
        deleteEntityContact(existingContacts[index].entityContactId).then(
          (res) => {
            setExistingContacts(res.data);
            setIsLoading(false);
            showAlert("success", "Associated contact removed.");
          }
        );
      } else {
        props.handleSetContacts && props.handleSetContacts(newContacts);
        setExistingContacts(newContacts);
      }
    }
  };

  const handleContactSelected = (e: any, value: EventContactDto) => {
    // Adjust type
    if (value) {
      setAutoCompleteContactValue(null);
      const newContacts = _.cloneDeep(existingContacts);
      const existingContact = newContacts.find(
        (x) => x.id === value.id && x.type === value.type
      );

      if (!existingContact) {
        const contactsToSet = [...newContacts, { ...value }];

        if (props.entityId) {
          setIsLoading(true);
          createEntityContact({
            eventId: props.entityId,
            contactId:
              value.type === EventContactTypes.Contact ? value.id : null,
            contactGroupId:
              value.type === EventContactTypes.ContactGroup ? value.id : null,
          }).then((res) => {
            setExistingContacts(res.data);
            showAlert("success", "Contact added.");
            setIsLoading(false);
          });
        } else {
          setExistingContacts(contactsToSet);
          props.handleSetContacts && props.handleSetContacts(contactsToSet);
        }
      }
    }
  };

  const autoCompleteContactInputChange = (
    e: any,
    newValue: string,
    reason: string
  ) => {
    if (reason === "reset") {
      setAutoCompleteContactValue(null);
    } else {
      setAutoCompleteContactInputValue(newValue);
    }
  };

  const getContactOptions = () => {
    const contactOptions = options.filter(
      (x) =>
        x.type === EventContactTypes.Contact &&
        !existingContacts
          .filter((x) => x.type === EventContactTypes.Contact)
          .map((y) => y.id)
          .includes(x.id)
    );

    const contactGroupOptions = options.filter(
      (x) =>
        x.type === EventContactTypes.ContactGroup &&
        !existingContacts
          .filter((x) => x.type === EventContactTypes.ContactGroup)
          .map((y) => y.id)
          .includes(x.id)
    );

    return _.sortBy(
      [...contactOptions, ...contactGroupOptions],
      [(contact) => contact.name?.toLowerCase()]
    );
  };

  const handleContactClick = (
    event: React.MouseEvent<HTMLElement>,
    contact: EventContactDto
  ) => {
    // Adjust type
    setAnchorEl(event.currentTarget);
    setSelectedContact({
      type: contact.type,
      id: contact.id,
    });
  };

  const userRoleCanSave = roleMatch([
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
    UserRoles.EventsContributor,
  ]);
  const userCanDelete = roleMatch([UserRoles.EventsAdministrator]);

  const isViewerOnly = roleMatch([UserRoles.EventsViewer]);
  const isContributorOnly = roleMatch([UserRoles.EventsContributor]);

  return isViewerOnly && _.isEmpty(existingContacts) ? (
    <Box p={1}>No contacts</Box>
  ) : (
    <Dimmer active={isLoading || props.isLoading} loader>
      <Grid.Row>
        {!_.isEmpty(existingContacts) && (
          <Grid.Col md={12} width={12}>
            <MuiGrid container>
              {_.sortBy(existingContacts, [
                (contact) => contact.name?.toLowerCase(),
              ]).map((contact, index) => (
                <MuiGrid item key={index} mr={1} mb={1}>
                  <Chip
                    onClick={(e) => handleContactClick(e, contact)}
                    icon={
                      contact.type === EventContactTypes.ContactGroup ? (
                        <GroupIcon />
                      ) : (
                        <></>
                      )
                    }
                    label={`${contact.name}`}
                    variant="outlined"
                    sx={{
                      [`& .${chipClasses.icon}`]: {
                        color: "rgba(0, 0, 0, 0.26)",
                      },
                    }}
                    {...(!props.eventLocked && userRoleCanSave && userCanDelete
                      ? {
                          onDelete: () => handleContactDeleted(contact),
                        }
                      : {})}
                  />
                </MuiGrid>
              ))}
            </MuiGrid>
          </Grid.Col>
        )}
        {userRoleCanSave && (
          <>
            <Grid.Col md={12} width={12} className="mb-2 mt-1">
              <Autocomplete
                loading={optionsLoading}
                filterOptions={(x) => x}
                disabled={props.eventLocked}
                id="contact-grouped-search"
                options={getContactOptions()}
                noOptionsText={
                  !autoCompleteContactInputValue
                    ? "Enter text to search"
                    : "No options"
                }
                getOptionLabel={(option) =>
                  option.name + " " + option.contactGroupName
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.type === EventContactTypes.ContactGroup && (
                      <GroupIcon color="disabled" className="mr-2" />
                    )}
                    {option.name}
                    <Typography variant="body2" className="ml-2">
                      {option.contactGroupName}
                    </Typography>
                  </Box>
                )}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Search contacts or contact groups"
                  />
                )}
                onChange={handleContactSelected}
                value={autoCompleteContactValue}
                inputValue={autoCompleteContactInputValue}
                onInputChange={autoCompleteContactInputChange}
              />
            </Grid.Col>
            {!isContributorOnly && (
              <Grid.Col
                md={12}
                width={12}
                className="mb-2 mt-1 align-items-end d-flex"
              >
                <Button
                  variant="outlined"
                  onClick={() => setShowAddContact(true)}
                >
                  Create New Contact
                </Button>
                <Button
                  variant="outlined"
                  className="ml-2"
                  onClick={() => setShowAddContactGroup(true)}
                >
                  Create New Contact Group
                </Button>
              </Grid.Col>
            )}
          </>
        )}

        {showAddContact && (
          <LynxDialog
            dividers
            hasNoActions
            isCloseInHeader
            open={showAddContact}
            handleClose={() => setShowAddContact(false)}
            title={`Add Contact`}
            maxWidth="md"
            dialogContent={
              <ContactForm
                modalMode
                handleSave={(contact: any) => {
                  setShowAddContact(false);
                  setOptions([...options, contact]);
                }}
              />
            }
          />
        )}
        {showAddContactGroup && (
          <ContactGroupModal
            open={showAddContactGroup}
            handleGroupFormSave={() => setShowAddContactGroup(false)}
            handleClose={() => setShowAddContactGroup(false)}
          />
        )}
      </Grid.Row>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        disableScrollLock
        onClose={() => setAnchorEl(null)}
      >
        {!_.isEmpty(selectedContact) && (
          <ContactQuickCard
            id={selectedContact.id}
            type={selectedContact.type}
          />
        )}
      </Popover>
    </Dimmer>
  );
};

export default Contacts;
