import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Alert {
  message: string;
  variant: 'success' | 'error' | 'info' | 'warning';
}

export interface AlertState {
  alerts: Alert[];
}

const initialState: AlertState = {
  alerts: [],
};

const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<Alert>) => {
      state.alerts.push(action.payload);
    },
    clearAlerts: (state) => {
      state.alerts = [];
    },
  },
});

export const { addAlert, clearAlerts } = alertSlice.actions;

export default alertSlice.reducer;
