import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button, Divider,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText, Typography
} from "@mui/material";
import TimeAgo from "javascript-time-ago";
import _ from "lodash";
import moment from "moment";
import { Dimmer, Form, Grid } from "components/lynx-components";
import colors from "../../../modules/lynxColors";
import {
  createComment,
  deleteComment,
  getCommentsForEntity,
  updateComment,
} from "../../../services/comment-service";
import { UserRoles } from "../../../types/enums";
import { LynxDialog } from "../../lynx-dialog";
import CommentsInput from "./comments-input";
import useAlert from "hooks/useAlert";
import { getUserId, roleMatch } from "../../../actions/auth";
import { EntityCommentDto, UserDto } from "types";
import { tagParseToChip } from "services/helpers/tagParser";

interface Contact {
  type: string;
  id: string;
}

interface CommentsProps {
  entityId: number;
  entityType: string;
  users: UserDto[];
  handleAddComment: (loading: boolean) => void;
}

const Comments: React.FC<CommentsProps> = ({
  entityId,
  entityType,
  users,
  handleAddComment: handleAddCommentProps,
}) => {
  const [comments, setComments] = useState<EntityCommentDto[]>([]);
  const [newComment, setNewComment] = useState("");
  const [plainTextComment, setPlainTextComment] = useState("");
  const [commentCount, setCommentCount] = useState(5);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userData, setUserData] = useState<{ id: string; display: string }[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [commentToDelete, setCommentToDelete] =
    useState<EntityCommentDto | null>(null);
  const [commentToEdit, setCommentToEdit] = useState<EntityCommentDto | null>(
    null
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { showAlert } = useAlert();

  const isViewer = roleMatch([UserRoles.EventsViewer]);

  useEffect(() => {
    if (entityType && entityId) {
      loadComments();
    }
  }, [entityType, entityId]);

  const loadComments = () => {
    getCommentsForEntity(entityType, entityId).then((res) => {
      setComments(res.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!_.isEmpty(users)) {
      const usersToSet: any = users.map((user) => ({
        id: user.id,
        display: user.fullName,
      }));
      setUserData(usersToSet);
    }
  }, [users]);

  const timeAgo = new TimeAgo("en-US");

  const getInitials = (fullName: string) => {
    return fullName
      .split(" ")
      .map((n) => n[0])
      .join("");
  };

  const handleAddComment = () => {
    if (_.trim(newComment) === "") return;
    setIsLoading(true);
    handleAddCommentProps(true);

    const dtoToCreate = {
      eventId: entityId,
      comment: newComment,
      plainTextComment,
    };
    createComment(dtoToCreate)
      .then((res) => {
        setComments(res.data);
        setNewComment("");
        setPlainTextComment("");
        setIsLoading(false);
        handleAddCommentProps(false);
        showAlert("success", "Comment added.");
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
        setIsLoading(false);
      });
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const handleCommentDelete = (comment: EntityCommentDto) => {
    setCommentToDelete(comment);
    setShowDeleteDialog(true);
  };

  const handleConfirmDeleteComment = () => {
    setShowDeleteDialog(false);
    setIsLoading(true);
    deleteComment(commentToDelete!.id)
      .then(() => {
        showAlert("success", "Comment deleted.");
        setCommentToDelete(null);
        loadComments();
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
        setIsLoading(false);
      });
  };

  const handleCommentUpdate = () => {
    setIsLoading(true);
    updateComment(commentToEdit!.id, commentToEdit!)
      .then(() => {
        showAlert("success", "Comment saved.");
        setCommentToEdit(null);
        loadComments();
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
        setIsLoading(false);
      });
  };

  const userRoleCanSave = roleMatch([
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
    UserRoles.EventsContributor,
  ]);

  return isViewer && _.isEmpty(comments) ? (
    <Box p={1}>No comments</Box>
  ) : (
    <Dimmer active={isLoading} loader>
      {userRoleCanSave && (
        <Grid.Col md={12} width={12}>
          <Form.Group label="Add Comment" className="mb-0">
            <CommentsInput
              value={newComment}
              users={userData}
              onChange={(e: any, n: string, p: string) => {
                setNewComment(n);
                setPlainTextComment(p);
              }}
            />
          </Form.Group>
          <Grid.Col md={12} width={12} className="pl-0 mb-2">
            <Typography variant="caption">
              Type "@" to tag users or "#" to tag contacts or contact groups
            </Typography>
          </Grid.Col>
          <Grid.Col md={12} width={12}>
            <Button variant="outlined" onClick={handleAddComment}>
              Add Comment
            </Button>
          </Grid.Col>
        </Grid.Col>
      )}

      {comments.length > 0 && (
        <Grid.Col md={12} width={12}>
          <Form.Group className="mb-0">
            <List>
              <Divider />
              {comments.slice(0, commentCount).map((comment) => (
                <React.Fragment key={comment.id}>
                  <ListItem className="comment-item" alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        src="/broken-image.jpg"
                        sx={{ bgcolor: colors.harvestOrange }}
                      >
                        {getInitials(comment.createdByUserFullName as string)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            <b>{comment.createdByUserFullName}</b>&nbsp;-&nbsp;
                            {timeAgo.format(
                              moment.utc(comment.createdDateTimeUtc).valueOf()
                            )}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ whiteSpace: "pre-line", fontSize: "0.9rem" }}
                          >
                            {comment.comment && tagParseToChip(comment.comment)}
                          </Typography>
                        </>
                      }
                      secondary={
                        comment.createdByUserId === getUserId() && (
                          <div className="mt-2">
                            <Link
                              underline="hover"
                              href="#"
                              color="inherit"
                              className="mr-2"
                              onClick={() => setCommentToEdit(comment)}
                            >
                              <b>Edit</b>
                            </Link>
                            <Link
                              underline="hover"
                              href="#"
                              color="inherit"
                              onClick={() => handleCommentDelete(comment)}
                            >
                              <b>Delete</b>
                            </Link>
                          </div>
                        )
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
            {commentCount < comments.length && (
              <Button
                fullWidth
                onClick={() => setCommentCount(commentCount + 5)}
              >
                Load More Comments
              </Button>
            )}
          </Form.Group>
        </Grid.Col>
      )}

      <LynxDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onConfirm={handleConfirmDeleteComment}
        title="Delete Comment"
        content="Are you sure you want to delete this comment?"
      />

      <LynxDialog
        open={Boolean(commentToEdit)}
        onClose={() => setCommentToEdit(null)}
        onConfirm={handleCommentUpdate}
        title="Edit Comment"
      >
        <CommentsInput
          value={commentToEdit?.comment || ""}
          users={userData}
          onChange={(e: any, n: string) =>
            setCommentToEdit((prev) => ({ ...prev!, comment: n }))
          }
        />
      </LynxDialog>
    </Dimmer>
  );
};

export default Comments;
