import Button from "@mui/material/Button";
import { Card, Dimmer, Form, Grid, Icon } from "components/lynx-components";
import useAlert from "hooks/useAlert";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  useAddCustomerMutation,
  useUpdateCustomerMutation,
} from "services/rtkApi/endpoints/customers";
import { CustomerDto } from "types";
import { validationService } from "../../services/validation";
interface Props {
  selectedCustomer: CustomerDto | null;
  handleModalClose: () => void;
}
interface FormState extends CustomerDto {
  nameError?: string;
  userCountError?: string;
  incidentsLicenseCountError?: string;
  monitoringLicenseCountError?: string;
  eventsLicenseCountError?: string;
  inspectionsLicenseCountError?: string;
  energyLogsLicenseCountError?: string;
}
const initialForm: FormState = {
  name: "",
  nameError: "",
  userCount: 0,
  incidentsLicenseCount: 0,
  eventsLicenseCount: 0,
  monitoringLicenseCount: 0,
  inspectionsLicenseCount: 0,
  energyLogsLicenseCount: 0,
  licenseExpirationDateTimeUtc: "",
};

export function CustomerModal(props: Props) {
  const [formState, setFormState] = useState(initialForm);
  const [isLoading, setIsLoading] = useState(true);
  const [hasExpiration, setHasExpiration] = useState(false);
  const [addTrigger] = useAddCustomerMutation();
  const [updateTrigger] = useUpdateCustomerMutation();
  const { showAlert } = useAlert();
  const handleInputChange = (e: any) => {
    let newState = { ...formState };
    const name = e.target.name;
    const value =
      e.target.type == "checkbox" ? e.target.checked : e.target.value;
    _.set(newState, name, value);
    setFormState(newState);
  };

  useEffect(() => {
    if (props.selectedCustomer) {
      setFormStateFromProps(props.selectedCustomer);
    } else {
      setIsLoading(false);
    }
  }, [props.selectedCustomer]);

  const setFormStateFromProps = (data: CustomerDto) => {
    var expiredDate = data.licenseExpirationDateTimeUtc
      ? moment(data.licenseExpirationDateTimeUtc).format("YYYY-MM-DD HH:mm")
      : "";
    if (expiredDate != "") {
      setHasExpiration(true);
    }
    let newState = {
      name: data.name,
      userCount: data.userCount,
      id: data.id,
      monitoringLicenseCount: data.monitoringLicenseCount,
      eventsLicenseCount: data.eventsLicenseCount,
      incidentsLicenseCount: data.incidentsLicenseCount,
      inspectionsLicenseCount: data.inspectionsLicenseCount,
      energyLogsLicenseCount: data.energyLogsLicenseCount,
      licenseExpirationDateTimeUtc: expiredDate,
    };
    setFormState(newState);
    setIsLoading(false);
  };

  const handleSaveCustomer = () => {
    if (!validateDataForSave()) {
      return;
    }
    let formToSave = validationService.unsetErrors(
      formState,
      "nameError",
      "userCountError",
      "incidentsLicenseCountError",
      "monitoringLicenseCountError",
      "eventsLicenseCountError"
    );

    formToSave.licenseExpirationDateTimeUtc =
      formToSave.licenseExpirationDateTimeUtc == ""
        ? ""
        : moment(formToSave.licenseExpirationDateTimeUtc).utc().format();

    if (props.selectedCustomer) {
      updateTrigger(formToSave).then((res) => {
        if (res.data) {
          showAlert("success", `Customer updated.`);
          props.handleModalClose();
        }
      });
    } else {
      addTrigger(formToSave).then((res) => {
        if (res.data) {
          showAlert("success", `Customer created.`);
          props.handleModalClose();
        }
      });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );

    validationService.validateNumberField(
      newState,
      "userCount",
      "userCountError",
      true,
      "Total User Licenses"
    );

    validationService.validateNumberField(
      newState,
      "incidentsLicenseCount",
      "incidentsLicenseCountError",
      true,
      "Incidents License Count"
    );
    validationService.validateNumberField(
      newState,
      "monitoringLicenseCount",
      "monitoringLicenseCountError",
      true,
      "Monitoring License Count"
    );
    validationService.validateNumberField(
      newState,
      "eventsLicenseCount",
      "eventsLicenseCountError",
      true,
      "Events License Count"
    );
    validationService.validateNumberField(
      newState,
      "inspectionsLicenseCount",
      "inspectionsLicenseCountError",
      true,
      "Inspections License Count"
    );
    validationService.validateNumberField(
      newState,
      "energyLogsLicenseCount",
      "energyLogsLicenseCountError",
      true,
      "Energy Logs License Count"
    );

    isFormValid = !validationService.hasError(
      newState,
      "nameError",
      "userCountError",
      "incidentsLicenseCountError",
      "monitoringLicenseCountError",
      "eventsLicenseCountError"
    );

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  return (
    <Form className="card dsmodal-main w-50">
      <Card.Body>
        <Card.Title>
          {props.selectedCustomer ? "Edit Customer" : "Add Customer"}

          <Icon
            name="x"
            onClick={() => {
              props.handleModalClose();
            }}
            className="float-right pointer"
          ></Icon>
        </Card.Title>
        <Dimmer active={isLoading} loader>
          <Grid.Row>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Name" isRequired>
                <Form.Input
                  name="name"
                  onChange={handleInputChange}
                  value={formState.name}
                  error={formState.nameError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>

            <Grid.Col md={12} width={12}>
              <Form.Group label="Total User Licenses" isRequired>
                <Form.Input
                  type="number"
                  name={`userCount`}
                  onChange={handleInputChange}
                  value={formState.userCount}
                  error={formState.userCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Incidents License Count" isRequired>
                <Form.Input
                  type="number"
                  name={`incidentsLicenseCount`}
                  onChange={handleInputChange}
                  value={formState.incidentsLicenseCount}
                  error={formState.incidentsLicenseCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Events License Count" isRequired>
                <Form.Input
                  type="number"
                  name={`eventsLicenseCount`}
                  onChange={handleInputChange}
                  value={formState.eventsLicenseCount}
                  error={formState.eventsLicenseCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Monitoring License Count" isRequired>
                <Form.Input
                  type="number"
                  name={`monitoringLicenseCount`}
                  onChange={handleInputChange}
                  value={formState.monitoringLicenseCount}
                  error={formState.monitoringLicenseCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Inspections License Count" isRequired>
                <Form.Input
                  type="number"
                  name={`inspectionsLicenseCount`}
                  onChange={handleInputChange}
                  value={formState.inspectionsLicenseCount}
                  error={formState.inspectionsLicenseCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Energy Logs License Count" isRequired>
                <Form.Input
                  type="number"
                  name={`energyLogsLicenseCount`}
                  onChange={handleInputChange}
                  value={formState.energyLogsLicenseCount}
                  error={formState.energyLogsLicenseCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>

            <Grid.Col md={12} width={12}>
              <Form.Group label="">
                <Form.Checkbox
                  label="Set expiration date?"
                  name="hasExpiration"
                  checked={hasExpiration}
                  onChange={(e) => {
                    setHasExpiration(e.target.checked);
                    if (e.target.checked == false) {
                      setFormState({
                        ...formState,
                        licenseExpirationDateTimeUtc: "",
                      });
                    }
                  }}
                />
              </Form.Group>
            </Grid.Col>
            {hasExpiration && (
              <Grid.Col md={6} width={12}>
                <Form.Group label="Expiration Date">
                  <Form.Input
                    type="datetime-local"
                    value={formState.licenseExpirationDateTimeUtc?.toString()}
                    name="licenseExpirationDateTimeUtc"
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Grid.Col>
            )}
            {/* {!isLoading && props.selectedCustomer && (
              <Grid.Col md={12} width={12}>
                <div style={{ height: "500px" }}>
                  <LynxDataGrid
                    data={props.selectedCustomer.users}
                    columns={columns}
                    {...props}
                    entityName={"User"}
                    localStorageName="admin_users"
                    hasEditButton
                    entityModal={AdminUserModal}
                    passEntityOnClick
                    hasNoLookups
                  />
                </div>
              </Grid.Col>
            )} */}
          </Grid.Row>
        </Dimmer>
      </Card.Body>
      <Card.Footer>
        {!props.selectedCustomer && (
          <Button
            color="primary"
            className="float-right"
            onClick={handleSaveCustomer}
          >
            Add Customer
          </Button>
        )}
        {props.selectedCustomer && (
          <div>
            <Button color="error" className="float-left" disabled>
              Lock Customer
            </Button>

            <Button
              color="primary"
              className="float-right"
              onClick={handleSaveCustomer}
            >
              Save Customer
            </Button>
          </div>
        )}
      </Card.Footer>
    </Form>
  );
}
