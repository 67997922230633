import { apiUrl, client } from "modules/store";

export function getCustomers(params) {
  let url = `${apiUrl}/customers`;

  return client.get(url, {
    params: params,
  });
}

export function getUsersForCustomer(customerId, params) {
  let url = `${apiUrl}/users/customers/${customerId}`;

  return client.get(url, {
    params: params,
  });
}
