import Button from "@mui/material/Button";
import _ from "lodash";
import { MuiColorInput } from "mui-color-input";
import React, { useState } from "react";
import { Card, Form, Grid } from "components/lynx-components";
import { validationService } from "../../../services";
import { createLookup } from "../../../services/lookup";
import useAlert from "hooks/useAlert";
import { roleMatch } from "actions/auth";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import { LookupTypes, UserRoles } from "types/enums";
import { Typography } from "@mui/material";
import { UsersPermissions } from "./user-permissions";

export const defaultMonLocTypeColor = "#0000FF";
const initialForm = {
  code: "",
  description: "",
  color: defaultMonLocTypeColor,
};

export function AddLookupHeader(props) {
  const [formState, setFormState] = useState(initialForm);
  const [refresh, setRefresh] = useState(false);
  const [permissionsExpanded, setPermissionsExpanded] = useState(false);
  const [orgUserLookups, setOrgUserLookups] = useState([]);
  const { showAlert } = useAlert();
  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(formState, "codeError");
    if (props.id > 0) {
    } else {
      formToSave.id = 0;
      formToSave.lookupType = props.lookupType;
      formToSave.organizationUserIds = orgUserLookups.map(
        (x) => x.organizationUserId
      );
      createLookup(formToSave)
        .then((res) => {
          showAlert("success", `${props.lookupName} created.`);
          props.handleAddLookup();
          setFormState({
            ...initialForm,
            parentLookupId: res.data.parentLookupId,
          });
          setRefresh(!refresh);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "code",
      "codeError",
      props.codeLabel
    );

    if (props.hasColor) {
      validationService.validateRequiredField(
        newState,
        "color",
        "colorError",
        "Color"
      );
    }

    if (props.hasParentLookup) {
      validationService.validateRequiredField(
        newState,
        "parentLookupId",
        "parentLookupIdError",
        props.parentLookupLabel
      );
    }
    let errorFields = ["codeError"];
    if (props.hasParentLookup) {
      errorFields = [...errorFields, "parentLookupIdError"];
    }
    if (props.hasColor) {
      errorFields = [...errorFields, "colorError"];
    }
    isFormValid = !validationService.hasError(newState, ...errorFields);
    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };
  const handleColorChange = (color) => {
    setFormState({ ...formState, color: color });
  };

  return (
    <Form className="w-30 h-auto">
      <Card.Body>
        <Grid.Row>
          {props.hasParentLookup && (
            <Grid.Col md={5} width={12}>
              <Form.Group label={props.parentLookupLabel} isRequired>
                <Form.Select
                  name="parentLookupId"
                  onChange={handleInputChange}
                  value={formState.parentLookupId}
                  error={formState.parentLookupIdError}
                >
                  <option value={""}></option>
                  {props.parentLookups.map((type) => (
                    <option value={type.id} key={type.id}>
                      {type.code}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          )}
          <Grid.Col md={5} width={12}>
            <Form.Group label={props.codeLabel} isRequired>
              <Form.Input
                type="text"
                value={formState.code}
                name="code"
                onChange={handleInputChange}
                error={formState.codeError}
              />
            </Form.Group>
          </Grid.Col>
          {props.hasColor && (
            <Grid.Col md={5} width={12}>
              <Form.Group label="Color" isRequired>
                <MuiColorInput
                  format="hex"
                  variant="standard"
                  fallbackValue={defaultMonLocTypeColor}
                  value={formState.color}
                  onChange={handleColorChange}
                  error={formState.colorError}
                />
              </Form.Group>
            </Grid.Col>
          )}
          <Grid.Col
            md={props.hasParentLookup || props.hasColor ? 10 : 5}
            width={12}
          >
            <Form.Group label={props.descriptionLabel}>
              <Form.Input
                type="text"
                value={formState.description}
                name="description"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={2} width={12}>
            <Form.Group label="&nbsp;&nbsp;">
              <Button
                variant="contained"
                className="float-right"
                onClick={saveForm}
              >
                Add
              </Button>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        {((roleMatch([UserRoles.EventsAdministrator, UserRoles.Admin]) &&
          props.lookupType == LookupTypes.EventCategory) ||
          (roleMatch([UserRoles.Admin]) &&
            props.lookupType == LookupTypes.MonitoringEventType)) && (
          <Grid.Row>
            <Grid.Col md={12} width={12}>
              <Accordion
                className="w-100"
                defaultExpanded={false}
                expanded={permissionsExpanded}
                onChange={() => setPermissionsExpanded(!permissionsExpanded)}
              >
                <AccordionSummary
                  aria-controls="location-content"
                  id="permissions-header"
                >
                  <Typography>Permissions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <UsersPermissions
                    lookupType={props.lookupType}
                    lookupId={0}
                    refresh={refresh}
                    setOrganizationUserLookups={(e) => {
                      setOrgUserLookups(e);
                    }}
                  ></UsersPermissions>
                </AccordionDetails>
              </Accordion>
            </Grid.Col>
          </Grid.Row>
        )}
      </Card.Body>
    </Form>
  );
}
