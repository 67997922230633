import { Container, IconButton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { Grid } from "components/lynx-components";
import { BadgeWidget } from "../../dashboards/dashboards/widgets/badge-widget";
import { WylooMap } from "./wyloo-map";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { wylooTheme } from "./wyloo-theme";
import moment from "moment";
import { MonitoringLocationPortalTypes } from "../../../types/enums";
import { WylooLineChart } from "./wyloo-line-chart";
import { Dimmer } from "components/lynx-components";
import { useHistory, useLocation } from "react-router-dom";
import { navigateTo } from "services/navigation-service";
import { getPortalStats } from "services/customer-portal-service";
import { useSelector } from "react-redux";
import PageNotFound from "components/PageNotFound";
export function WylooMicrositeWater(props) {
  const [portalStats, setPortalStats] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [statsLoading, setStatsLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const portal = useSelector((state) => state.portal);
  useEffect(() => {
    getPortalStats(
      [
        MonitoringLocationPortalTypes.SurfaceWater,
        MonitoringLocationPortalTypes.GroundWater,
      ],
      location.pathname
    )
      .then((response) => {
        setPortalStats(response.data);
      })
      .finally(() => {
        setStatsLoading(false);
      });
  }, []);

  const handleLocationSelected = (e) => {
    setSelectedLocation(e);
  };

  return (
    <ThemeProvider theme={wylooTheme}>
      {portal.isLoading || (!portal.isLoading && portal.isEnabled) ? (
        <div className="wyloo-microsite-container wyloo-colors ">
          <Dimmer active={portal.isLoading} loader>
            <div className="header-container-wyloo" style={{}}>
              <img
                alt="wyloo-header-image"
                className="wyloo-header-image"
                src="https://lynx-public.s3.amazonaws.com/wyloo/portal/images/Mincor-220906-016-64.png"
              ></img>
              <div className="wyloo-header-text">
                <Typography variant="h2">
                  <IconButton
                    onClick={(e) => navigateTo(history, "/wyloo/", e)}
                    variant="outlined"
                    className="mr-5"
                    sx={{ color: "white", border: "3px solid white" }}
                    size="large"
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  {`Water Monitoring`}
                </Typography>
              </div>
            </div>
            <Container maxWidth="xl">
              <Grid className="">
                <Grid.Row>
                  <Grid.Col width={12} lg={6} className="mt-2">
                    <div className="wyloo-text-section">
                      <Typography sx={{ width: "fit-content" }}>
                        Water quality monitoring is a fundamental aspect of the
                        Project, underscored by our commitment to a closed-loop
                        process plant. In addition, the layout of Eagle’s Nest
                        is being optimized to reduce the potential for water
                        interaction with the Project and related infrastructure.
                        Surface and groundwater will be monitored in alignment
                        with best management practices, permitting requirements
                        and Provincial Water Quality Objectives (Ontario) prior
                        to discharge.
                      </Typography>
                    </div>
                  </Grid.Col>
                  <Grid.Col width={12} lg={3} sm={6} className="mt-2">
                    <BadgeWidget
                      title={"Total Sample Events"}
                      value={portalStats.totalSampleEvents}
                      subValue={`Between ${moment(
                        portalStats.firstSampleDate
                      ).format("MMM YYYY")} and ${moment(
                        portalStats.lastSampleDate
                      ).format("MMM YYYY")}`}
                      className=" portal-badge"
                      isLoading={statsLoading}
                    />
                  </Grid.Col>
                  <Grid.Col width={12} lg={3} sm={6} className="mt-2 ">
                    <BadgeWidget
                      title={"Unique Parameters Sampled"}
                      value={portalStats.distinctParametersSampled}
                      subValue="Across ground and surface water"
                      className="portal-badge"
                      isLoading={statsLoading}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={12} md={12} className="mt-2">
                    <div className="wyloo-text-section">
                      <Typography sx={{ width: "fit-content" }}>
                        The surface water quality sampling program is conducted
                        routinely at 27 groundwater wells, 28 surface water
                        stations and four flow stations, which have been
                        strategically identified around the Project area based
                        on the local and regional watershed delineations, both
                        upstream and downstream from the Project, to understand
                        and identify potential areas of influence.
                      </Typography>
                      <Typography
                        sx={{ width: "fit-content" }}
                        className="mt-2"
                      >
                        Our expanding groundwater monitoring program aims to
                        evaluate the local water quality monthly, which provides
                        another input to modelling the water balance and water
                        quality modelling at the Project site.
                      </Typography>
                      <Typography
                        sx={{ width: "fit-content" }}
                        className="mt-2"
                      >
                        Through our data management system in Lynx, we complete
                        routine checks and conduct quarterly reviews of data
                        trends to understand seasonal changes. These reviews
                        also ensure that our spatial coverage is sufficient,
                        factoring in multiple discipline inputs.
                      </Typography>
                      <Typography
                        sx={{ width: "fit-content" }}
                        className="mt-2"
                      >
                        The hydrogeology component of the baseline study focuses
                        on assessing the existing hydrogeological conditions
                        within the Project area. The assessment utilizes the
                        existing monitoring well network on site, with
                        additional locations installed in 2024 to further
                        complement data collection, providing Wyloo with a
                        comprehensive understanding of hydrogeological dynamics
                        and communication around the Project. We continue to
                        review available datasets to understand potential
                        changes in the environment, aligning the data with
                        multi-disciplinary efforts to enhance our understanding
                        of geographical conditions in the Project area.
                      </Typography>
                    </div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={12} lg={6} className="mt-2 mb-5">
                    <WylooMap
                      selectedLocation={selectedLocation}
                      portalTypes={[
                        MonitoringLocationPortalTypes.SurfaceWater,
                        MonitoringLocationPortalTypes.GroundWater,
                      ]}
                    />
                  </Grid.Col>
                  <Grid.Col width={12} lg={6} className="mt-2">
                    <WylooLineChart
                      handleLocationSelected={handleLocationSelected}
                      portalTypes={[
                        MonitoringLocationPortalTypes.SurfaceWater,
                        MonitoringLocationPortalTypes.GroundWater,
                      ]}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            </Container>
          </Dimmer>
        </div>
      ) : (
        <div className="content-wrapper">
          <PageNotFound />
        </div>
      )}
    </ThemeProvider>
  );
}
