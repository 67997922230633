import { apiUrl, client } from "../modules/store";

export function getContacts(params) {
  return client.get(apiUrl + "/contacts", {
    params: params,
  });
}
export function createContact(dto) {
  return client.post(apiUrl + "/contacts", {
    ...dto,
  });
}

export function mergeContact(dto) {
  return client.post(apiUrl + "/contacts/merge", {
    ...dto,
  });
}

export function deleteContact(id) {
  return client.delete(apiUrl + "/contacts/" + id);
}

export function patchContact(id, dto) {
  return client.patch(apiUrl + "/contacts/" + id, dto);
}

export function getContact(id) {
  return client.get(apiUrl + "/contacts/" + id);
}

export function getContactGroup(id) {
  return client.get(apiUrl + "/contacts/groups/" + id);
}
export function deleteContactGroup(id) {
  return client.delete(apiUrl + "/contacts/groups/" + id);
}

export function getContactGroups(params = {}, showAll = true) {
  if (showAll) {
    params.pageSize = 10000;
  }
  return client.get(apiUrl + "/contacts/groups", {
    params: params,
  });
}
export function createContactGroup(dto) {
  return client.post(apiUrl + "/contacts/groups", {
    ...dto,
  });
}
export function searchContacts(query, includeUsers = false) {
  return client.get(
    `${apiUrl}/contacts/search?query=${query}&includeUsers=${includeUsers}`
  );
}
export function getEntityContacts(entityType, entityId) {
  return client.get(
    `${apiUrl}/contacts/entity?entityType=${entityType}&entityId=${entityId}`
  );
}

export function deleteEntityContact(id) {
  return client.delete(`${apiUrl}/contacts/entity/${id}`);
}
export function createEntityContact(dto) {
  return client.post(`${apiUrl}/contacts/entity`, dto);
}
export function patchContactGroup(id, dto) {
  return client.patch(apiUrl + "/contacts/groups/" + id, dto);
}
