import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { SearchableSelect } from "../../form-controls/searchable-select";
import { Form } from "components/lynx-components";
import { LynxDialog } from "./../../lynx-dialog";
import { EntityTypes, LookupTypes } from "../../../types/enums";
import { getParameters } from "../../../services/parameters";
import { getLookups } from "../../../services/lookup";
import Typography from "@mui/material/Typography";

export function Step4(props) {
  const [showInfo, setShowInfo] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [parameters, setParameters] = useState([]);
  const [units, setUnits] = useState([]);
  useEffect(() => {
    //load params and units
    if (props.entityType == EntityTypes.TimeSeries) {
      getParameters().then((res) => {
        setParameters(res.data);
      }, []);
      getLookups(LookupTypes.Unit).then((res) => {
        setUnits(res.data);
      });
    }
  }, []);
  const getTimeseriesOptions = (isCurrentDateTime) => {
    var defaultOptions = ["Result Value", "Result Metadata"];
    if (
      !props.fieldMappings.find((x) => x.isTimeSeriesDateTime) ||
      isCurrentDateTime
    ) {
      defaultOptions = ["DateTime", ...defaultOptions];
    }
    return defaultOptions;
  };
  const handleDropdownChange = (newValue, index) => {
    let newMappings = [...props.fieldMappings];
    newMappings[index].lynxField = newValue.field;
    props.setFieldMappings(newMappings);
  };

  const handleTimeSeriesDropdownChange = (newValue, index) => {
    let newMappings = [...props.fieldMappings];

    if (newValue == "DateTime") {
      newMappings[index].isTimeSeriesDateTime = true;
      newMappings[index].isTimeSeriesMetadata = false;
      newMappings[index].isResultValue = false;
      newMappings[index].parameterId = null;
      newMappings[index].unitId = null;
    }
    if (newValue == "Result Metadata") {
      newMappings[index].isTimeSeriesMetadata = true;
      newMappings[index].isTimeSeriesDateTime = false;
      newMappings[index].isResultValue = false;
      newMappings[index].parameterId = null;
      newMappings[index].unitId = null;
    }
    if (newValue == "Result Value") {
      newMappings[index].isResultValue = true;
      newMappings[index].isTimeSeriesMetadata = false;
      newMappings[index].isTimeSeriesDateTime = false;
    }
    if (!newValue) {
      newMappings[index].isTimeSeriesMetadata = false;
      newMappings[index].isTimeSeriesDateTime = false;
      newMappings[index].isResultValue = false;
      newMappings[index].parameterId = null;
      newMappings[index].unitId = null;
    }
    props.setFieldMappings(newMappings);
  };

  const handleParameterChange = (newValue, index) => {
    let newMappings = [...props.fieldMappings];
    newMappings[index].parameterId = newValue.id;
    props.setFieldMappings(newMappings);
  };
  const handleUnitChange = (newValue, index) => {
    let newMappings = [...props.fieldMappings];
    newMappings[index].unitId = newValue.id;
    props.setFieldMappings(newMappings);
  };

  const getFieldDropdown = (i) => {
    if (props.entityType == EntityTypes.TimeSeries) {
      return (
        <>
          <Typography variant="subtitle2">Type</Typography>
          <SearchableSelect
            name={`lynxField_${i}`}
            key={`lynxField_${i}`}
            options={getTimeseriesOptions(
              props.fieldMappings[i].isTimeSeriesDateTime
            )}
            labelFunction={(value) => {
              return value;
            }}
            value={
              props.fieldMappings[i].isTimeSeriesDateTime
                ? "DateTime"
                : props.fieldMappings[i].isTimeSeriesMetadata
                ? "Result Metadata"
                : props.fieldMappings[i].isResultValue
                ? "Result Value"
                : null
            }
            placeholder={"Choose from list"}
            onChange={(e) => {
              handleTimeSeriesDropdownChange(e, i);
            }}
          />
          {props.fieldMappings[i].isResultValue && (
            <div>
              <Typography variant="subtitle2" className="mt-2 ">
                Parameter
              </Typography>
              <SearchableSelect
                name={`parameter_${i}`}
                key={`parameter_${i}`}
                options={parameters}
                labelField="name"
                idField="id"
                id="parameterId"
                value={props.fieldMappings[i].parameterId}
                placeholder={"Parameter - Search or choose from list"}
                onChange={(e) => {
                  handleParameterChange(e, i);
                }}
              />
              <Typography variant="subtitle2" className="mt-2 ">
                Unit
              </Typography>
              <SearchableSelect
                name={`unit_${i}`}
                key={`unit_${i}`}
                options={units}
                labelField="code"
                idField="id"
                id="unitId"
                value={props.fieldMappings[i].unitId}
                placeholder={"Unit - Search or choose from list"}
                onChange={(e) => {
                  handleUnitChange(e, i);
                }}
              />
            </div>
          )}
          {props.fieldMappings[i].isTimeSeriesMetadata && (
            <>
              <Typography variant="subtitle2" className="mt-2 ">
                Label
              </Typography>
              <Form.Input
                name={`label_${i}`}
                key={`label_${i}`}
                onChange={(e) => {
                  props.handleLabelChange(e, i);
                }}
                value={props.fieldMappings[i].timeSeriesMetadataLabel ?? ""}
              ></Form.Input>
            </>
          )}
          {props.fieldMappings[i].error && (
            <Typography variant="subtitle1" className="mt-2 text-red">
              {props.fieldMappings[i].error}
            </Typography>
          )}
        </>
      );
    }
    return (
      <SearchableSelect
        name={`lynxField_${i}`}
        key={`lynxField_${i}`}
        options={props.lynxFields.filter(
          (x) =>
            !props.fieldMappings
              .filter((x, index) => index != i)
              .map((a) => a.lynxField)
              .includes(x.field)
        )}
        labelFunction={(value) => {
          if (props.entityType == EntityTypes.MonitoringResult) {
            if (value.isEventField) {
              return `${value.headerName} (Event)`;
            } else {
              return `${value.headerName} (Result)`;
            }
          }

          return value.headerName;
        }}
        idField="field"
        value={props.fieldMappings[i].lynxField}
        placeholder={"Search or choose from list"}
        onChange={(e) => {
          handleDropdownChange(e, i);
        }}
      />
    );
  };

  const handleDelete = () => {
    props.handleDeleteMapping(deleteItem);
    setDeleteItem({});
    setShowDelete(false);
  };

  const getSpecTimeseriesValue = (mapping) => {
    return mapping.parameterId &&
      mapping.unitId &&
      parameters.find((x) => x.id == mapping.parameterId) &&
      units.find((x) => x.id == mapping.unitId)
      ? `${parameters.find((x) => x.id == mapping.parameterId).name} (${
          units.find((x) => x.id == mapping.unitId).code
        })`
      : mapping.isTimeSeriesMetadata
      ? mapping.timeSeriesMetadataLabel
      : mapping.isTimeSeriesDateTime
      ? "DateTime"
      : "";
  };

  const getExistingSpecColumnValue = (mapping) => {
    return props.entityType == EntityTypes.TimeSeries
      ? getSpecTimeseriesValue(mapping)
      : props.lynxFields.find((x) => x.field == mapping.fieldName)
      ? props.lynxFields.find((x) => x.field == mapping.fieldName).headerName
      : mapping.fieldName;
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width={"50%"}>Import Column</TableCell>
              <TableCell>Lynx Field</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.formState.assetName != "" && (
              <TableRow>
                <TableCell>Asset Name</TableCell>
                <TableCell>
                  = <b>{props.formState.assetName}</b>
                </TableCell>
              </TableRow>
            )}
            {props.formState.monitoringLocationName != "" && (
              <TableRow>
                <TableCell>Monitoring Location Name</TableCell>
                <TableCell>
                  = <b>{props.formState.monitoringLocationName}</b>
                </TableCell>
              </TableRow>
            )}
            {props.formState.parentLookupCode != "" && (
              <TableRow>
                <TableCell>
                  {props.entityType == EntityTypes.MonitoringResult
                    ? "Event Type"
                    : "Category"}
                </TableCell>
                <TableCell>
                  = <b>{props.formState.parentLookupCode}</b>
                </TableCell>
              </TableRow>
            )}
            {props.formState.childLookupCode != "" && (
              <TableRow>
                <TableCell>Subcategory</TableCell>
                <TableCell>
                  = <b>{props.formState.childLookupCode}</b>
                </TableCell>
              </TableRow>
            )}
            {_.isEmpty(props.selectedSpec) &&
              props.fieldMappings.map((mapping, i) => (
                <TableRow
                  key={mapping.importColumn}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{mapping.importColumn}</TableCell>
                  <TableCell>{getFieldDropdown(i)}</TableCell>
                </TableRow>
              ))}
            {!_.isEmpty(props.selectedSpec) &&
              props.selectedSpec.importFieldMappings.map((mapping) => (
                <TableRow
                  key={mapping.importColumnName}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{mapping.importColumnName}</TableCell>
                  <TableCell>{getExistingSpecColumnValue(mapping)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            onClick={props.handleNext}
            sx={{ mt: 1, mr: 1 }}
          >
            Continue
          </Button>
          <Button onClick={props.handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
        </div>
      </Box>
      <LynxDialog
        open={showInfo}
        title={"Existing configuration column not present in the import file."}
        description={
          "The existing import configuration contains a column that is not preset in this import file. You can ignore it, or remove from the import configuration permanently."
        }
        handleConfirm={() => setShowInfo(false)}
      />
      <LynxDialog
        open={showDelete}
        title={"Delete mapping from the existing import configuration?"}
        handleConfirm={() => handleDelete()}
        handleClose={() => setShowDelete(false)}
      />
    </>
  );
}
