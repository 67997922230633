import { apiUrl, client } from "../modules/store";
import _ from "lodash";
import queryString from "query-string";
export function downloadWord(html) {
  return client.post(
    `${apiUrl}/reports`,
    { html: _.toString(html) },
    {
      responseType: "blob",
    }
  );
}

export function getReports(entityType) {
  return client.get(apiUrl + "/reports?entityType=" + entityType);
}

export function runReportById(
  id,
  filter,
  eventId,
  parentLookupId,
  childLookupId
) {
  var url = `${apiUrl}/reports/${id}`;
  var qs = {};
  if (filter) {
    qs = { filter: filter, ...qs };
  }
  if (eventId) {
    qs = { eventId: eventId, ...qs };
  }
  if (parentLookupId) {
    qs = { parentLookupId: parentLookupId, ...qs };
  }
  if (childLookupId) {
    qs = { childLookupId: childLookupId, ...qs };
  }
  url = url + "?" + queryString.stringify(qs);
  return client.get(url, {
    responseType: "blob",
  });
}
export function runIncidentReport(reportId, incidentId) {
  var url = `${apiUrl}/reports/${reportId}/incidents/${incidentId}`;

  return client.get(url, {
    responseType: "blob",
  });
}
export function runEventReport(reportId, eventId) {
  var url = `${apiUrl}/reports/${reportId}/events/${eventId}`;

  return client.get(url, {
    responseType: "blob",
  });
}

export function runIncidentsReport(reportId, params) {
  return client.get(apiUrl + `/reports/${reportId}/incidents/`, {
    params: params,
    responseType: "blob",
  });
}
export function runEventsReport(reportId, params) {
  return client.get(apiUrl + `/reports/${reportId}/events/`, {
    params: params,
    responseType: "blob",
  });
}
