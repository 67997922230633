import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import _ from "lodash";
import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import { updateIncidentUser } from "../../../services/incident-service";
import CircularProgress from "@mui/material/CircularProgress";
import "./incident-form.css";
import { Box, IconButton, Stack } from "@mui/material";
import { LynxDialog } from "../../lynx-dialog";
import SingleSelect from "components/form-controls/single-select";
import { MultiSelect } from "components/form-controls/multi-select";
import { useSelector } from "react-redux";
import { Lock, LockOpenOutlined } from "@mui/icons-material";
import { IncidentUserTypes, UserRoles } from "types/enums";
import { roleMatch } from "actions/auth";
import useAlert from "hooks/useAlert";
import { RootState } from "types";
import { IncidentDto, UserDto } from "types";

interface IncidentInvestigationTeamProps {
  isLocked: boolean;
  toggleLock: (section: string) => void;
  incident: IncidentDto;
  resetIsLocked: () => void;
  onUpdateInvestigationTeam: (data: any) => void; // Specify the type as needed
}

const IncidentInvestigationTeam: React.FC<IncidentInvestigationTeamProps> = ({
  isLocked,
  toggleLock,
  incident,
  resetIsLocked,
  onUpdateInvestigationTeam,
}) => {
  const allUsers = useSelector((state: RootState) => state.lookups.users);
  const users = useMemo(
    () => allUsers?.filter((x) => x.isIncidentsUser) || [],
    [allUsers]
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedLead, setSelectedLead] = useState<number | undefined>(
    undefined
  );
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<UserDto[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
  const { showAlert } = useAlert();

  useEffect(() => {
    if (!users) return;
    setSelectedLead(incident.investigationLeadUserId);

    const teamMembers = incident.incidentUsers
      ? (incident.incidentUsers
          .filter((f) => f.type === IncidentUserTypes.InvestigationTeamMember)
          .map((m) => users.find((f) => f.id === m.userId))
          .filter(Boolean) as UserDto[])
      : [];
    setSelectedTeamMembers(teamMembers);
    setIsLoading(false);
  }, [incident, users]);

  const onSaveInvestigationTeam = async () => {
    setIsSaving(true);
    const payload = {
      investigationLeadUserId: selectedLead,
      incidentUsers: selectedTeamMembers.map((m) => ({
        incidentId: incident.id,
        userId: m.id,
      })),
    };

    try {
      const res = await updateIncidentUser(incident.id, payload);
      showAlert("success", "Investigation team updated.");
      onUpdateInvestigationTeam(res.data);
      resetIsLocked();
    } catch (error) {
      showAlert("error", "Failed to update investigation team.");
    } finally {
      setIsSaving(false);
      setIsWarningOpen(false);
    }
  };

  const checkIsTeamChanged = (): boolean => {
    return (
      incident.investigationLeadUserId !== selectedLead ||
      !_.isEqual(
        incident.incidentUsers
          ?.filter((f) => f.type === IncidentUserTypes.InvestigationTeamMember)
          .map((m) => m.userId),
        selectedTeamMembers.map((m) => m.id)
      )
    );
  };

  const getWarningMessage = (): string => {
    const currentUsers = [
      incident.incidentUsers?.some(
        (s) => s.userId === incident.investigationLeadUserId
      )
        ? null
        : incident.investigationLeadUserId,
      ...(incident.incidentUsers?.map((m) => m.userId) || []),
    ].filter(Boolean);

    const selectedUsers = [
      selectedTeamMembers.some((s) => s.id === selectedLead)
        ? null
        : selectedLead,
      ...selectedTeamMembers.map((m) => m.id),
    ].filter(Boolean);

    const addedUsers = [...new Set(selectedUsers)].filter(
      (item) => !currentUsers.includes(item)
    );
    const addedUserNames = addedUsers
      .map((m) => users?.find((f) => f.id === m)?.fullName || "")
      .filter(Boolean);

    return addedUsers.length > 0
      ? `Are you sure you want to add user${
          addedUsers.length > 1 ? "s" : ""
        } (${addedUserNames.join(
          ", "
        )}) to the investigation team? They will have access to view all incident and investigation details.`
      : "Are you sure you want to change the investigation team? The users may lose access to the investigation.";
  };

  return (
    <Box>
      <Form className="card mb-5">
        <Dimmer active={isLoading} loader>
          <Card.Header>
            <Card.Title>
              <Stack direction="row" alignItems="center">
                <span>Investigation Team</span>
                {roleMatch([UserRoles.Admin]) &&
                  incident?.status?.toLowerCase() === "closed" && (
                    <IconButton
                      aria-label="Lock"
                      onClick={() => toggleLock("investigationTeam")}
                      title={isLocked ? "Unlock" : "Lock"}
                    >
                      {isLocked ? (
                        <Lock />
                      ) : (
                        <LockOpenOutlined color="success" />
                      )}
                    </IconButton>
                  )}
              </Stack>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Investigation Lead">
                  {selectedLead && (
                    <SingleSelect
                      onChange={(e: any) => {
                        setSelectedLead(e.target.value);
                      }}
                      dropdownValues={users}
                      label="fullName"
                      id="id"
                      name="investigationLeadUserId"
                      value={selectedLead ?? ""}
                      disabled={isLocked}
                    />
                  )}
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Investigation Team Members">
                  <MultiSelect
                    name="investigationTeam"
                    id="investigationTeam"
                    value={selectedTeamMembers}
                    dropdownValues={users?.filter((f) => f.id !== selectedLead)}
                    onChange={(e: any) => {
                      setSelectedTeamMembers(e.target.value);
                    }}
                    key="id"
                    label="fullName"
                    disabled={isLocked}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        </Dimmer>
        <Card.Footer>
          <Button
            variant="contained"
            className="float-right"
            onClick={() => {
              if (checkIsTeamChanged()) {
                setIsWarningOpen(true);
              } else {
                onSaveInvestigationTeam();
              }
            }}
            disabled={isLocked}
          >
            Save
          </Button>
        </Card.Footer>
      </Form>
      {isSaving && (
        <LynxDialog
          open={isSaving}
          title="Saving investigation team. Do not close the window."
          description={
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          }
        />
      )}
      <LynxDialog
        title="Warning"
        description={getWarningMessage()}
        open={isWarningOpen}
        handleClose={() => setIsWarningOpen(false)}
        buttons={() => (
          <Button
            variant="contained"
            color="success"
            onClick={onSaveInvestigationTeam}
          >
            Yes
          </Button>
        )}
      />
    </Box>
  );
};

export default IncidentInvestigationTeam;
